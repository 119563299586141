/// <reference path='common.ts' />

/**
 * datalistおよびselectに対する操作を行うクラスです。
 * HTMLのDOM構築完了時およびloadイベント発生時に自動的に内部のformエレメントを管理対象とします。
 *
 * 属性
 * data-url: optionを取得するURL
 * data-value-field: valueに設定するフィールド名
 * data-label-field: ラベルに設定するフィールド名
 *
 * イベント
 * fetch: 通信が完了した場合（detailはrequestとresponseのPromise）
 * fetch-error: 通信中にエラーが発生した場合（detailはrequestとエラー内容）
 */
class HaoriDatalist {
  static fetch(element: HTMLSelectElement | HTMLDataListElement) {
    let url = element.getAttribute('data-url');
    let request: RequestInit = {
      mode: 'cors',
      credentials: 'same-origin',
    };
    let savedResponse: Response;
    fetch(url, request)
      .then((response) => {
        savedResponse = response;
        let contentType = response.headers.get('Content-Type');
        if (
          contentType != null &&
          contentType.indexOf('application/json') == 0
        ) {
          return response.json();
        } else {
          return response.text();
        }
      })
      .then((result) => {
        let valueName = element.getAttribute('data-value-field');
        let labelName = element.getAttribute('data-label-field');
        if (result.list) {
          (<HashObject[]>result.list).forEach((object) => {
            let option = document.createElement('option');
            option.setAttribute(
              'value',
              Haori.getValue(object, valueName).toString()
            );
            option.textContent = Haori.getValue(object, labelName).toString();
            element.appendChild(option);
          });
        }
        element.dispatchEvent(
          new CustomEvent('fetch', {
            bubbles: true,
            cancelable: true,
            composed: true,
            detail: {
              request: request,
              response: {
                ok: savedResponse.ok,
                status: savedResponse.status,
                body: result,
              },
            },
          })
        );
      })
      .catch((reason) => {
        console.error(reason);
        element.dispatchEvent(
          new CustomEvent('fetch-error', {
            bubbles: true,
            cancelable: true,
            composed: true,
            detail: {
              request: request,
              reason: reason,
            },
          })
        );
      });
  }
}

document.addEventListener('DOMContentLoaded', (event) => {
  document.body
    .querySelectorAll('select[data-url], datalist[data-url]')
    .forEach((element) => {
      HaoriDatalist.fetch(<HTMLSelectElement | HTMLDataListElement>element);
    });
});

document.addEventListener('load', (event) => {
  (<HTMLElement>event.target)
    .querySelectorAll('select[data-url], datalist[data-url]')
    .forEach((element) => {
      HaoriDatalist.fetch(<HTMLSelectElement | HTMLDataListElement>element);
    });
});
