/// <reference path='common.ts' />

/**
 * データの取得結果によって配下のエレメントの挙動を変えます。
 *
 * 属性
 * data-fetch-url: データを取得するためのURL
 * data-fetch-object: 取得したデータ
 *
 * 属性（配下エレメント）
 * data-fetch-visible: 表示判定スクリプト（falseなら非表示、nullなら変更なし、その他なら元の表示状態とする）（フェッチ結果が変数となる）
 * data-fetch-text: 表示内容スクリプト（戻り値が内容になる）（フェッチ結果が変数となる）
 *
 * イベント
 * fetch: 通信が完了した場合（detailはrequestとresponseのPromise）
 * fetch-error: 通信中にエラーが発生した場合（detailはrequestとエラー内容）
 */
class HaoriFetch {
  /**
   * AJAXでデータを取得し、そのデータで配下エレメントの表示状態を変更します。
   *
   * @param parent 親エレメント
   */
  static fetch(parent: Element) {
    let url = parent.getAttribute('data-fetch-url');
    let request: RequestInit = {
      mode: 'cors',
      credentials: 'same-origin',
    };
    let savedResponse: Response;
    fetch(url, request)
      .then((response) => {
        savedResponse = response;
        let contentType = response.headers.get('Content-Type');
        if (
          contentType != null &&
          contentType.indexOf('application/json') == 0
        ) {
          return response.json();
        } else {
          return response.text();
        }
      })
      .then((result: HashObject) => {
        parent.setAttribute('data-fetch-object', JSON.stringify(result));
        HaoriFetch.processing(parent, result);
        parent.dispatchEvent(
          new CustomEvent('fetch', {
            bubbles: true,
            cancelable: true,
            composed: true,
            detail: {
              request: request,
              response: {
                ok: savedResponse.ok,
                status: savedResponse.status,
                body: result,
              },
            },
          })
        );
      })
      .catch((reason) => {
        console.error(reason);
        parent.dispatchEvent(
          new CustomEvent('fetch-error', {
            bubbles: true,
            cancelable: true,
            composed: true,
            detail: {
              request: request,
              reason: reason,
            },
          })
        );
      });
  }

  static processing(parent: Element, object: HashObject) {
    let define = '"use strict"; ';
    for (let key in object) {
      let value = object[key];
      define += `var ${key} = ${JSON.stringify(value)}; `;
    }
    parent
      .querySelectorAll('[data-fetch-visible]')
      .forEach((element: Element) => {
        let script = element.getAttribute('data-fetch-visible');
        let func = define + `return (${script});`;
        try {
          let result = Function(func)();
          if (result === false) {
            (<HTMLElement>element).style.display = 'none';
          } else if (result != null) {
            (<HTMLElement>element).style.display = null;
          }
        } catch (error) {
          console.error(
            'data-fetch-visibleの実行に失敗しました',
            element,
            error,
            func
          );
        }
      });
    parent.querySelectorAll('[data-fetch-text]').forEach((element: Element) => {
      let script = element.getAttribute('data-fetch-text');
      let func = define + `return (${script});`;
      try {
        element.textContent = Function(func)();
      } catch (error) {
        console.error(
          'data-fetch-visibleの実行に失敗しました',
          element,
          error,
          func
        );
      }
    });
  }
}

document.addEventListener('DOMContentLoaded', (event) => {
  document.body
    .querySelectorAll('[data-fetch-url]')
    .forEach((element: Element) => {
      HaoriFetch.fetch(element);
    });
});

document.addEventListener('load', (event) => {
  let target = <HTMLElement>event.target;
  target.querySelectorAll('[data-fetch-url]').forEach((element: Element) => {
    HaoriFetch.fetch(element);
  });

  let closest = target;
  while (true) {
    closest = closest.closest('[data-fetch-url][data-fetch-object]');
    if (closest == null) {
      break;
    }
    HaoriFetch.processing(
      target,
      JSON.parse(closest.getAttribute('[data-fetch-object]'))
    );
  }
});
