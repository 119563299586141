/// <reference path='common.ts' />

/**
 * テーブルに対する操作を行うクラスです。
 * tbodyタグの最終行をサンプルとして使用し、テーブル構築時に非表示にします。
 * HTMLのDOM構築完了時およびloadイベント発生時に自動的に内部のtableエレメントを管理対象とします。
 * 行追加時に行内のエレメントの属性に「{}」で囲んだプレースホルダがある場合はパラメータと置換します。
 *
 * 属性
 * data-fetch: この属性が付いているテーブルのみ操作対象とします
 * data-url: データ取得対象となるURL（{}で囲んだ部分はパラメータと置換します）
 * data-params: データの検索条件（JSON形式）
 * data-auto-fields: trueの場合はfieldsパラメータに表示フィールド名がカンマ区切りで設定されます
 * data-table-binded: Haoriのイベントを関連付けた際に付与されます（外部からは変更しないでください）
 * data-total: データの条件一致件数（外部からは変更しないでください）
 * data-count: 現在のデータの取得件数（外部からは変更しないでください）
 * data-hash: データのハッシュキー（外部からは変更しないでください）
 * data-status: 最後のレスポンスのステータス番号（外部からは変更しないでください）
 * data-error: エラー発生時のメッセージ（外部からは変更しないでください）
 * data-top-row: 表示中の行の中で一番上にある行の番号（外部からは変更しないでください）
 * data-bottom-row: 表示中の行の中で一番下にある行の番号（外部からは変更しないでください）
 * data-busy: 処理中の場合に定義される（外部からは変更しないでください）
 *
 * 属性（thead内のth）
 * data-name: ソートするパラメータ名（ソート可能とする場合に付与します）
 * data-sort: ソート順（asc: 昇順、desc: 降順）
 *
 * 属性（tbody内のtr）
 * data-index: 行番号（0始まり）
 * data-object: 行に割り当てられたデータのJSON
 *
 * 属性（tbody内のtd）
 * data-name: 表示する対象となるパラメータ名
 * data-direct: 値をtextContentではなくinnerHTMLで設定します
 * data-default: 値がnullもしくは未設定の場合のデフォルト値
 * data-script: 値を整形する処理を定義します（value: パラメータ値、object: 全体のオブジェクト）
 * data-suffix: 内容が存在するときに後ろに付与する文字列 
 *
 * 属性（tbody内のtd内のエレメント）
 * data-disabled: 条件に一致するものにdisabled属性を付与します（value: パラメータ値、object: 全体のオブジェクト）
 * 
 * 属性（tfoot）
 * data-content: 行がある場合に表示する文字列（total、count、topRow、bottomRow、errorのプレースホルダが使えます）
 * data-no-content: 行がない場合に表示する文字列（total、count、topRow、bottomRow、errorのプレースホルダが使えます）
 * data-error-content: エラーが発生している場合に表示する文字列（total、count、topRow、bottomRow、errorのプレースホルダが使えます）
 * topRowとbottomRowは1始まりとします。
 * 
 * 属性（hfoot内のthもしくはtd）
 * data-script: 表示する文字列の処理を定義します（total、count、topRow、bottomRow、status、error）
 * topRowとbottomRowは1始まりとします。
 *
 * イベント
 * fetch: 通信が完了した場合（detailはrequestとresponseのPromise）
 * add-row: 行が追加された際に発生します（detailはrow: trエレメント、object: 追加された行に割り当てられたデータ）
 */
class HaoriTable {
  /**
   * parentエレメント以下のテーブルエレメントにイベントを関連付け最初のフェッチを行います。
   */
  static bind(parent: HTMLElement) {
    Array.prototype.forEach.call(
      parent.querySelectorAll('table[data-fetch]'),
      (table: HTMLTableElement) => {
        if (table.getAttribute('data-table-binded') != null || table.tHead == null) {
          return;
        }
        table.setAttribute('data-table-binded', '');
        table.tHead.querySelectorAll('th, td').forEach((cell) => {
          cell.addEventListener('click', (event) => {
            if ((<Element>event.target).getAttribute('data-name') == null || table.tHead == null) {
              return;
            }
            table.tHead
              .querySelectorAll('th[data-sort], td[data-sort]')
              .forEach((current) => {
                if (current != null && current != cell) {
                  current.removeAttribute('data-sort');
                }
              });
            let name = cell.getAttribute('data-name');
            if (name == null) {
              return;
            }
            let sort = cell.getAttribute('data-sort');
            if (sort == null) {
              cell.setAttribute('data-sort', 'asc');
            } else if (sort == 'asc') {
              cell.setAttribute('data-sort', 'desc');
            } else if (sort == 'desc') {
              cell.removeAttribute('data-sort');
            }
            HaoriTable.fetch(table);
          });
        });
        let lastRow = table.tBodies[0].querySelector('tr:last-child');
        (<HTMLElement>lastRow).style.display = 'none';
        HaoriTable.fetch(table);
      }
    );
  }

  /**
   * 新しい検索条件でテーブルのデータを再構築します。
   *
   * @param table テーブルエレメント
   */
  static fetch(table: HTMLTableElement) {
    table.removeAttribute('data-total');
    table.removeAttribute('data-count');
    table.removeAttribute('data-hash');
    let tbody = table.tBodies[0];
    let lastRow = tbody.querySelector('tr:last-child');
    if (lastRow == null) {
      return;
    }
    lastRow.remove();
    tbody.innerHTML = '';
    tbody.append(lastRow);
    HaoriTable.fetchNext(table);
  }

  /**
   * 必要に応じて次のリストを取得します。
   *
   * @param table テーブルエレメント
   */
  static fetchNext(table: HTMLTableElement) {
    if (table.getAttribute('data-url') == null) {
      return;
    }

    HaoriTable.updateStatus(table);

    let total = parseInt(table.getAttribute('data-total') || '0');
    let count = parseInt(table.getAttribute('data-count') || '0');
    if (table.getAttribute('data-total') != null && count >= total) {
      return;
    }
    let hash = table.getAttribute('data-hash');

    let lastRow = table.tBodies[0].querySelector('tr:nth-last-child(2)');
    if (lastRow != null) {
      let lastRowRect = lastRow.getBoundingClientRect();
      if (table.tFoot) {
        let tFootRect = table.tFoot.getBoundingClientRect();
        if (tFootRect.top < lastRowRect.top - lastRowRect.height * 40) {
          // 2回分先に読み込まれているためスキップする
          return;
        }
      }
    }

    if (table.getAttribute('data-busy') != null) {
      return;
    }
    table.setAttribute('data-busy', '');

    table.removeAttribute('data-status');
    table.removeAttribute('data-error');

    let params = new URLSearchParams();
    if (table.getAttribute('data-params') != null) {
      let dataParam = <HashObject>JSON.parse(table.getAttribute('data-params') || '{}');
      for (let key in dataParam) {
        let value = dataParam[key];
        params.set(key, value);
      }
    }
    params.set('offset', count.toString());
    params.set('limit', '20');
    if (hash) {
      params.set('hash', hash);
    }
    if (table.tHead) {
      let sort = table.tHead.querySelector(
        'th[data-name][data-sort], td[data-name][data-sort]'
      );
      if (sort != null) {
        let sortName = sort.getAttribute('data-name');
        params.set(
          'sort',
          (sort.getAttribute('data-sort') == 'desc' ? '-' : '') + sortName
        );
      }
    }
    let url = table.getAttribute('data-url') || '';
    if (url.indexOf('?') < 0) {
      url += '?';
    } else {
      url += '&';
    }
    params.forEach((value, key) => {
      if (url.indexOf('{' + key + '}') < 0) {
        return;
      }
      url = url.replace(new RegExp('\{' + key + '\}', 'g'), value.toString());
      params.delete(key);
    });

    let request: RequestInit = {
      mode: 'cors',
      credentials: 'same-origin',
    };
    let savedResponse: Response;
    fetch(url + params.toString(), request)
      .then((response) => {
        savedResponse = response;
        table.setAttribute('data-status', response.status.toString());
        if (response.ok == false) {
          table.removeAttribute('data-busy');
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (savedResponse.ok) {
          HaoriTable.appendRows(table, result.list, result.total, result.hash);
        }
        table.removeAttribute('data-busy');
        table.dispatchEvent(
          new CustomEvent('fetch', {
            bubbles: true,
            cancelable: true,
            composed: true,
            detail: {
              request: request,
              response: {
                ok: savedResponse.ok,
                status: savedResponse.status,
                body: result,
              },
            },
          })
        );
        if (savedResponse.ok) {
          HaoriTable.fetchNext(table);
        }
      })
      .catch((reason) => {
        table.setAttribute('data-error', reason);
        table.removeAttribute('data-busy');
        console.error(reason);
      });
  }

  /**
   * テーブルに行を追加します。
   *
   * @param table 対象テーブル
   * @param list 追加するデータのリスト
   * @param total 全部の件数
   * @param hash 検索ハッシュ
   */
  static appendRows(
    table: HTMLTableElement,
    list: object[],
    total: number,
    hash: string | null = null
  ) {
    table.setAttribute('data-total', total.toString());
    if (hash != null) {
      table.setAttribute('data-hash', hash);
    }
    let count = parseInt(table.getAttribute('data-count') || '0');
    let tbody = table.tBodies[0];
    let lastRow = tbody.querySelector('tr:last-child');
    if (lastRow == null) {
      console.error('サンプル行が設定されていません');
      return;
    }
    (<HTMLElement>lastRow).style.display = 'none';
    let samples = lastRow.querySelectorAll('th, td');
    list.forEach((object, index) => {
      let tr = document.createElement('tr');
      samples.forEach((sample) => {
        let td = document.createElement('td');
        let name = sample.getAttribute('data-name');
        let value = Haori.getValue(object, name);
        if (Array.isArray(value)) {
          value = value.join('\n');
        }
        if (
          (value === null || value === undefined) &&
          sample.getAttribute('data-default') != null
        ) {
          value = sample.getAttribute('data-default');
        }
        let script = sample.getAttribute('data-script');
        if (script != null) {
          let func = `'use strict'; var object = ${JSON.stringify(
            object
          )}; var value = ${JSON.stringify(value)}; return (${script});`;
          try {
            value = Function(func)();
          } catch (error) {
            console.error(
              'data-scriptの実行に失敗しました',
              sample,
              error,
              func
            );
          }
        }
        if (sample.getAttribute('data-suffix') != null && value != null && value != '') {
          value = value + <string>sample.getAttribute('data-suffix');
        }
        if (sample.getAttribute('data-direct') != null) {
          td.innerHTML =
            sample.innerHTML + (value != null ? value.toString() : '');
        } else {
          td.innerHTML = sample.innerHTML;
          if (value != null) {
            td.appendChild(document.createTextNode(value.toString()));
          }
        }
        if (sample.getAttribute('class') != null) {
          td.setAttribute('class', <string>sample.getAttribute('class'));
        }
        tr.setAttribute('data-index', (count + index).toString());
        tr.setAttribute('data-object', JSON.stringify(object));
        tr.appendChild(td);
      });
      tr.querySelectorAll('*').forEach(element => {
        element.getAttributeNames().forEach(name => {
          let value = <string>element.getAttribute(name);
          if (value.indexOf('{') < 0) {
            return;
          }
          for (let key in object) {
            let targetValue = Haori.getValue(object, key);
            if (value.indexOf('{' + key + '}') < 0) {
              continue;
            }
            value = value.replace(new RegExp('\{' + key + '\}', 'g'), targetValue.toString());
          }
          element.setAttribute(name, value);
        });
      });
      (<Element>lastRow).before(tr);
      table.dispatchEvent(
        new CustomEvent('add-row', {
          bubbles: true,
          cancelable: true,
          composed: true,
          detail: {
            row: tr,
            object: object,
          },
        })
      );
    });
    table.setAttribute('data-count', (count + list.length).toString());
  }

  /**
   * スクロール状態とフッタの表示を更新します。
   *
   * @param table テーブルエレメント
   */
  static updateStatus(table: HTMLTableElement) {
    let count = parseInt(table.getAttribute('data-count') || '0');
    if (count > 0) {
      if (table.tHead) {
        let tHeadRect = table.tHead.getBoundingClientRect();
        let topCell = document.elementFromPoint(
          tHeadRect.left + 1,
          tHeadRect.bottom + 1
        );
        if (topCell != null) {
          let topRow = topCell.closest('tr');
          if (topRow != null && topRow.getAttribute('data-index') != null) {
            table.setAttribute('data-top-row', <string>topRow.getAttribute('data-index'));
          }
        }
      }

      if (table.tFoot) {
        let tFootRect = table.tFoot.getBoundingClientRect();
        let bottomCell = document.elementFromPoint(
          tFootRect.left + 1,
          tFootRect.top - 1
        );
        if (bottomCell != null) {
          let bottomRow = bottomCell.closest('tr');
          if (bottomRow != null && bottomRow.getAttribute('data-index') != null) {
            table.setAttribute(
              'data-bottom-row',
              <string>bottomRow.getAttribute('data-index')
            );
          }
        }
      }
    }

    if (table.tFoot) {
      if (table.tFoot.getElementsByTagName('tr').length == 0) {
        let tr = document.createElement('tr');
        let td = document.createElement('td');
        if (table.tHead) {
          td.setAttribute('colspan', table.tHead.querySelectorAll('th, td').length.toString());
        }
        tr.appendChild(td);
        table.tFoot.appendChild(tr);
      }
      let total = table.getAttribute('data-total') || '0';
      let count = table.getAttribute('data-count') || '0';
      let topRow = table.getAttribute('data-top-row') ? (parseInt(<string> table.getAttribute('data-top-row')) + 1).toString() : null;
      let bottomRow = table.getAttribute('data-bottom-row') ? (parseInt(<string> table.getAttribute('data-bottom-row')) + 1).toString() : null;
      let error = table.getAttribute('data-error');
      let footContent: string | null = null;
      if (error && table.tFoot.getAttribute('data-error-content') != null) {
        footContent = table.tFoot.getAttribute('data-error-content');
      } else if (parseInt(count) == 0 && table.tFoot.getAttribute('data-no-content') != null) {
        footContent = table.tFoot.getAttribute('data-no-content');
      } else if (table.tFoot.getAttribute('data-content') != null) {
        footContent = table.tFoot.getAttribute('data-content');
      }
      if (footContent != null) {
        let td = table.tFoot.querySelector('th, td');
        footContent = footContent.replace('{total}', total);
        footContent = footContent.replace('{count}', count);
        if (topRow != null) {
          footContent = footContent.replace('{topRow}', topRow);
        }
        if (bottomRow != null) {
          footContent = footContent.replace('{bottomRow}', bottomRow);
        }
        if (error != null) {
          footContent = footContent.replace('{error}', error);
        }
        if (td != null) {
          td.textContent = footContent;
        }
      }
      table.tFoot.querySelectorAll('th, td').forEach((cell) => {
        let script = cell.getAttribute('data-script');
        if (script != null) {
          let func =
            `'use strict';` +
            ` var total = ${table.getAttribute('data-total') || 0};` +
            ` var count = ${table.getAttribute('data-count') || 0};` +
            ` var topRow = ${table.getAttribute('data-top-row') ? parseInt(<string>table.getAttribute('data-top-row')) + 1 : null};` +
            ` var bottomRow = ${table.getAttribute('data-bottom-row') ? parseInt(<string>table.getAttribute('data-bottom-row')) + 1 : null};` +
            ` var status = ${table.getAttribute('data-status')};` +
            ` var error = '${table.getAttribute('data-error')}';` +
            ` return (${script});`;
          try {
            let text = Function(func)();
            cell.textContent = text;
          } catch (error) {
            console.error('data-scriptの実行に失敗しました', cell, error, func);
          }
        }
      });
    }
  }
}

window.addEventListener('load', (event) => {
  HaoriTable.bind(document.body);
});

document.body.addEventListener('load', (event) => {
  HaoriTable.bind(<HTMLElement>event.target);
});

let haoriTableBusy = false;
window.addEventListener(
  'scroll',
  (event) => {
    if (haoriTableBusy) {
      return;
    }
    haoriTableBusy = true;
    requestAnimationFrame(() => {
      Array.prototype.forEach.call(
        (<HTMLElement>event.target).querySelectorAll('table[data-fetch]'),
        (table: HTMLTableElement) => {
          HaoriTable.fetchNext(table);
        }
      );
      haoriTableBusy = false;
    });
  },
  true
);
