/**
 * 一般的なオブジェクト
 */
class HashObject {
  [key: string]: any;
}

/**
 * 共通クラスです。
 */
class Haori {
  /** オプション */
  static options: HaoriOptions;

  /**
   * @param options オプション設定
   */
  static setOptions(options: HaoriOptions) {
    Haori.options = (<any>Object).assign(new HaoriOptions(), options);
  }

  /**
   * objectのnameパラメータの値を取得します。nameにドットがある場合は入れ子のオブジェクトとして扱います。
   *
   * @param object オブジェクト
   * @param name パラメータ名
   * @returns 値
   */
  static getValue(object: HashObject, name: string | null): any {
    if (object == undefined || name == undefined || name == null) {
      return undefined;
    }
    let point = name.indexOf('.');
    if (point > 0) {
      let childName = name.substring(point + 1);
      let value = object[name.substring(0, point)];
      if (Array.isArray(value)) {
        let array: HashObject = [];
        value.forEach(child => {
          array.push(Haori.getValue(child, childName));
        });
        return array;
      } else {
        return Haori.getValue(value, childName);
      }
    }
    return object[name];
  }
}

/**
 * オプション設定を行うクラスです。
 */
class HaoriOptions {
  /** アラート処理 */
  alertProcess: (message: string, nextProcess: () => void) => void;

  /** 確認処理 */
  confirmProcess: (
    message: string,
    nextProcess: (result: boolean) => void
  ) => void;

  /** AJAX通信のリクエストコンバータ（戻り値はURL） */
  requestConverter: (url: string, request: RequestInit) => string;

  /** AJAX通信のレスポンスコンバータ */
  responseConverter: (
    url: string,
    request: RequestInit,
    response: Response
  ) => Promise<Response>;

  /** エラーメッセージコンバータ */
  errorMessageConverter: (
    url: string,
    request: RequestInit,
    response: Response,
    message: any
  ) => any;

  constructor() {
    this.alertProcess = (message, nextProcess) => {
      if (nextProcess) {
        nextProcess();
      }
    };
    this.confirmProcess = (message, nextProcess) => {
      let result = confirm(message);
      if (nextProcess) {
        nextProcess(result);
      }
    };
    this.requestConverter = (url, request) => {
      return url;
    };
    this.responseConverter = async (url, request, response) => {
      return response;
    };
    this.errorMessageConverter = (url, request, respnse, message) => {
      return message;
    };
  }
}
Haori.options = new HaoriOptions();
