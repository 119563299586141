/// <reference path='common.ts' />
/// <reference path='form.ts' />

/**
 * ボタンに対する操作を行うクラスです。オプションはHaoriFormのものを使用します。
 *
 * 属性
 * data-url: ボタンクリック時に指定のURLを呼び出します
 * data-method: ボタンクリック時に呼び出すメソッドです
 * data-enctype: ボタンクリック時に送信するContentTypeです
 * data-body: ボタンクリック時に送信するデータです
 * data-status: 最後のレスポンスのステータス番号（外部からは変更しないでください）
 * data-confirm: 確認ダイアログのメッセージ
 * data-redirect: 処理が成功した場合のリダイレクト先アドレス（data-urlが存在しない場合はそのままリダイレクトされます）
 * data-increment: 対象となるinputタグのセレクタ（クリックするとinputの値がインクリメントします）
 * data-decrement: 対象となるinputタグのセレクタ（クリックするとinputの値がデクリメントします）
 * data-download: data-urlに親フォームのパラメータを付与したURLに遷移します
 * 
 * イベント
 * fetch: 通信が完了した場合（detailはリクエスト（request）とレスポンスデータ（response）)
 * fetch-error: 通信中にエラーが発生した場合（detailはrequestとエラー内容）
 */
class HaoriButton {
  static processing(button: HTMLButtonElement, event: MouseEvent) {
    if (button.getAttribute('data-url') != null) {
      event.preventDefault();
      let url = <string>button.getAttribute('data-url');
      if (button.getAttribute('data-download') == null) {
        let headers = new Headers();
        if (button.getAttribute('data-enctype') != null) {
          headers.append('Content-Type', <string>button.getAttribute('data-enctype'));
        }
        let request: RequestInit = {
          mode: 'cors',
          credentials: 'same-origin',
          headers: headers,
          method: button.getAttribute('data-method') || 'GET',
          body: button.getAttribute('data-body'),
        };
        url = Haori.options.requestConverter(url, request);
        let savedResponse: Response;
        fetch(url, request)
          .then((response) => {
            Haori.options
              .responseConverter(url, request, response)
              .then((response) => {
                savedResponse = response;
                if (response.ok && button.getAttribute('data-redirect') != null) {
                  location.href = <string>button.getAttribute('data-redirect');
                }
                let contentType = response.headers.get('Content-Type');
                if (
                  contentType != null &&
                  contentType.indexOf('application/json') == 0
                ) {
                  return response.json();
                } else {
                  return response.text();
                }
              });
          })
          .then((result) => {
            button.dispatchEvent(
              new CustomEvent('fetch', {
                bubbles: true,
                cancelable: true,
                composed: true,
                detail: {
                  request: request,
                  response: {
                    ok: savedResponse.ok,
                    status: savedResponse.status,
                    body: result,
                  },
                },
              })
            );
          })
          .catch((reason) => {
            console.error(reason);
            button.dispatchEvent(
              new CustomEvent('fetch-error', {
                bubbles: true,
                cancelable: true,
                composed: true,
                detail: {
                  request: request,
                  reason: reason,
                },
              })
            );
          });
      } else {
        let form = button.closest('form');
        if (form == null) {
          console.error('フォームが存在しません', button);
        } else {
          let params = HaoriForm.getQueryParameter(form);
          if (url.indexOf('?') > 0) {
            url = url + '&' + params;
          } else {
            url = url + '?' + params;
          }
          location.href = url;
        }
      }
    } else if (button.getAttribute('data-redirect') != null) {
      event.preventDefault();
      location.href = <string>button.getAttribute('data-redirect');
    }
    if (button.getAttribute('data-increment') != null) {
      event.preventDefault();
      let target = document.querySelector(<string>button.getAttribute('data-increment'));
      HaoriButton.changeValue(<HTMLInputElement>target, true);
    }
    if (button.getAttribute('data-decrement') != null) {
      event.preventDefault();
      let target = document.querySelector(<string>button.getAttribute('data-decrement'));
      HaoriButton.changeValue(<HTMLInputElement>target, false);
    }
  }

  /**
   * inputエレメントの値を増加もしくは減少します。
   * 
   * @param element 
   * @param isIncrement 
   */
  static changeValue(element: HTMLInputElement, isIncrement: boolean) {
    let type = element.getAttribute('type');
    let currentValue = element.value;
    let newValue;
    switch (type) {
      case 'number': {
        if (element.value == '') {
          newValue = "0";
          break;
        }
        let value = Number(element.value || element.getAttribute('min'));
        let step = Number(element.getAttribute('step') || '1');
        if (isIncrement) {
          value = value + step;
        } else {
          value = value - step;
        }
        value = Math.round(value * 100000000) / 100000000.0; // 丸め誤差を修正する
        if (element.getAttribute('min') != null) {
          let min = Number(<string>element.getAttribute('min'));
          if (value < min) {
            value = min;
          }
        }
        if (element.getAttribute('max') != null) {
          let max = Number(<string>element.getAttribute('max'));
          if (max < value) {
            value = max;
          }
        }
        newValue = value.toString();
        break;
      }
      case 'date': {
        if (element.value == '') {
          newValue = new Date().toLocaleDateString('ja-JP', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }).split('/').join('-');
          break;
        }
        let value = new Date(element.value);
        let step = parseInt(element.getAttribute('step') || '1');
        if (isIncrement) {
          value.setDate(value.getDate() + step);
        } else {
          value.setDate(value.getDate() - step);
        }
        if (element.getAttribute('min') != null) {
          let min = new Date(<string>element.getAttribute('min'));
          if (value < min) {
            value = min;
          }
        }
        if (element.getAttribute('max') != null) {
          let max = new Date(<string>element.getAttribute('max'));
          if (max < value) {
            value = max;
          }
        }
        newValue = value.toLocaleDateString('ja-JP', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }).split('/').join('-');
        break;
      }
      case 'month': {
        if (element.value == '') {
          newValue = new Date().toLocaleDateString('ja-JP', {
            year: 'numeric',
            month: '2-digit'
          }).split('/', 2).join('-');
          break;
        }
        let value = new Date(element.value + '-01');
        let step = parseInt(element.getAttribute('step') || '1');
        if (isIncrement) {
          value.setMonth(value.getMonth() + step);
        } else {
          value.setMonth(value.getMonth() - step);
        }
        if (element.getAttribute('min') != null) {
          let min = new Date(<string>element.getAttribute('min') + '-01');
          if (value < min) {
            value = min;
          }
        }
        if (element.getAttribute('max') != null) {
          let max = new Date(<string>element.getAttribute('max') + '-01');
          if (max < value) {
            value = max;
          }
        }
        newValue = value.toLocaleDateString('ja-JP', {
          year: 'numeric',
          month: '2-digit'
        }).split('/', 2).join('-');
        break;
      }
      default:
        console.error('type属性の値(' + type + ')はサポートしていません');
    }
    if (currentValue != newValue) {
      element.value = newValue;
      element.dispatchEvent(
        new Event('change', {
          bubbles: true,
          cancelable: true,
          composed: true,
        })
      );
    }
  }
}

document.addEventListener('click', (event) => {
  let button = (<HTMLElement>event.target).closest('button');
  if (button == null) {
    return;
  }
  let message = button.getAttribute('data-confirm');
  if (message != null) {
    Haori.options.confirmProcess(message, (result) => {
      if (result) {
        HaoriButton.processing(<HTMLButtonElement>button, event);
      }
    });
  } else {
    HaoriButton.processing(<HTMLButtonElement>button, event);
  }
});
