/// <reference path='common.ts' />

/**
 * フォームに対する操作を行うクラスです。
 * HTMLのDOM構築完了時およびloadイベント発生時に自動的に内部のformエレメントを管理対象とします。
 *
 * 属性
 * action: submit時の送信先URL（プレースホルダ{name}がある場合は設定値と置き換えられます）
 * method: submit時のHTTPメソッド
 * enctype: submit時に送信するContent-Type
 * data-form-binded: イベントを関連付けた際に付与されます（外部からは変更しないでください）
 * data-fetch: この属性が付いているフォームのみ操作対象とします
 * data-status: 最後のレスポンスのステータス番号（外部からは変更しないでください）
 * data-redirect: 通信が正常に完了した場合のリダイレクト先
 * data-download: 通信が正常に完了した場合にダウンロードする場合のファイル名（actionと同様にプレースホルダが使用できます）
 *
 * 属性（フォーム中のボタン）
 * data-form-disabled: 通信中でdisabledになっている場合
 *
 * イベント
 * add-message: formもしくは入力エレメントにメッセージが追加された場合（detailはメッセージ）
 * clear-messages: メッセージのクリア要求あった場合
 * fetch: 通信が完了した場合（detailはrequestとresponse）
 * fetch-error: 通信中にエラーが発生した場合（detailはrequestとエラー内容）
 */
class HaoriForm {
  /** オプション */
  static options: HaoriFormOptions;

  /**
   * @param options オプション設定
   */
  static setOptions(options: HaoriFormOptions) {
    HaoriForm.options = (<any>Object).assign(new HaoriFormOptions(), options);
  }

  /**
   * parentエレメント以下のフォームエレメントにイベントを関連付けます。
   *
   * @param parent 親エレメント
   */
  static bind(parent: HTMLElement) {
    Array.prototype.forEach.call(
      parent.getElementsByTagName('form'),
      (form: HTMLFormElement) => {
        if (form.getAttribute('data-form-binded') != null) {
          return;
        }
        form.setAttribute('data-form-binded', '');
        form.addEventListener('submit', HaoriForm.formSubmitted);
      }
    );
  }

  /**
   * フォームがサブミットされた際の処理です。
   *
   * @param event
   */
  private static formSubmitted(event: Event) {
    let form = <HTMLFormElement>event.target;
    if (
      form.getAttribute('data-fetch') == null ||
      form.getAttribute('action') == null
    ) {
      return;
    }
    event.preventDefault();

    let url = form.getAttribute('action');
    let method = form.getAttribute('method') || 'GET';
    let enctype =
      form.getAttribute('enctype') || 'application/x-www-form-urlencoded';

    let body: string = null;
    let values = HaoriForm.getValues(form);
    for (let key in values) {
      if (url.indexOf(`{${key}}`) >= 0) {
        url = url.replace(`{${key}}`, values[key]);
        delete values[key];
      }
    }
    if (method.toUpperCase() == 'GET') {
      if (url.indexOf('?') < 0) {
        url += '?';
      } else {
        url += '&';
      }
      url += HaoriForm.getQueryParameterFromObject(values);
    } else {
      if (enctype == 'application/json') {
        body = JSON.stringify(values);
      } else {
        body = HaoriForm.getQueryParameterFromObject(values);
      }
    }

    HaoriForm.clearAllMessages(form);

    let request: RequestInit = {
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Content-Type': enctype,
      },
      method: method,
      body: body,
    };
    HaoriForm.fetch(form, url, request, (response) => {
      let redirect = form.getAttribute('data-redirect');
      if (redirect != null) {
        location.href = redirect;
      }

      let filename = form.getAttribute('data-download');
      if (filename != null) {
        let values = HaoriForm.getValues(form);
        for (let key in values) {
          filename = filename.replace(
            `{${key}}`,
            values[key].replace('/', '_')
          );
        }

        let anchor = document.createElement('a');
        anchor.href = URL.createObjectURL(response);
        anchor.download = filename;
        anchor.click();
      }
    });
  }

  /**
   * アドレスからデータをフェッチし入力欄に値を設定します。
   *
   * @param form フォームエレメント
   * @param url 取得先URL
   */
  static fetchValues(form: HTMLFormElement, url: string) {
    let request: RequestInit = {
      mode: 'cors',
      credentials: 'same-origin',
    };
    url = Haori.options.requestConverter(url, request);
    HaoriForm.fetch(form, url, request, (object) => {
      HaoriForm.setValues(form, object);
    });
  }

  /**
   * フォームにあるボタンを無効化します。
   *
   * @param form フォームエレメント
   */
  private static disableButtons(form: HTMLFormElement) {
    form
      .querySelectorAll(
        'input[type="submit"]:not([disabled]),input[type="reset"]:not([disabled]),button:not([disabled])'
      )
      .forEach((element) => {
        element.setAttribute('data-form-disabled', '');
        element.setAttribute('disabled', '');
      });
  }

  /**
   * フォームにある無効化されたボタンを再有効化します。disableBUttonsで無効化されたもののみを対象とします。
   *
   * @param form フォームエレメント
   */
  private static enableButtons(form: HTMLFormElement) {
    form
      .querySelectorAll(
        'input[type="submit"][data-form-disabled],input[type="reset"][data-form-disabled],button[data-form-disabled]'
      )
      .forEach((element) => {
        element.removeAttribute('data-form-disabled');
        element.removeAttribute('disabled');
      });
  }

  /**
   * 通信を行います。
   *
   * @param form フォームエレメント
   * @param url 送信先URL
   * @param request リクエストパラメータ
   * @param callback 送信完了後のコールバック関数
   */
  static fetch(
    form: HTMLFormElement,
    url: string,
    request: RequestInit,
    callback: (object: any) => void = null
  ) {
    if (form.getAttribute('data-busy') != null) {
      return;
    }
    form.setAttribute('data-busy', '');

    HaoriForm.disableButtons(form);
    form.removeAttribute('data-status');
    form.removeAttribute('data-error');
    HaoriForm.clearAllMessages(form);

    url = Haori.options.requestConverter(url, request);
    fetch(url, request)
      .then(response => {
        HaoriForm.enableButtons(form);
        Haori.options
          .responseConverter(url, request, response)
          .then(innserResponse => {
            response = innserResponse;
            form.setAttribute('data-status', response.status.toString());
            form.removeAttribute('data-busy');
            let contentType = response.headers.get('Content-Type');
            if (contentType != null) {
              if (contentType.indexOf('application/json') == 0) {
                return response.json();
              } else if (contentType.indexOf('text/') == 0) {
                return response.text();
              } else {
                return response.blob();
              }
            }
          })
          .then(result => {
            if (response.ok) {
              if (callback != null) {
                callback(result);
              }
            } else {
              result = Haori.options.errorMessageConverter(
                url,
                request,
                response,
                result
              );
              if (Array.isArray(result)) {
                result.forEach((object: HashObject) => {
                  HaoriForm.addErrorMessage(form, object.key, object.message);
                });
              } else {
                HaoriForm.addErrorMessage(form, null, result);
              }
            }
            form.dispatchEvent(
              new CustomEvent('fetch', {
                bubbles: true,
                cancelable: true,
                composed: true,
                detail: {
                  request: request,
                  response: {
                    ok: response.ok,
                    status: response.status,
                    body: result,
                  },
                },
              })
            );
          });
      })
      .catch((reason) => {
        HaoriForm.enableButtons(form);
        form.setAttribute('data-error', reason);
        form.removeAttribute('data-busy');
        console.error(reason);
        form.dispatchEvent(
          new CustomEvent('fetch-error', {
            bubbles: true,
            cancelable: true,
            composed: true,
            detail: {
              request: request,
              reason: reason,
            },
          })
        );
      });
  }

  /**
   * フォーム内の入力エレメントに設定されている値をオブジェクトとして取得します。
   *
   * @param form フォームエレメント
   */
  static getValues(form: HTMLFormElement): HashObject {
    return HaoriForm.getChildValues(form, []);
  }

  /**
   * 親エレメント内の入力エレメントに設定されている値をオブジェクトとして取得します。
   *
   * @param parent 親エレメント
   * @param elements 処理済みエレメントのリスト
   */
  private static getChildValues(
    parent: Element,
    elements: Element[]
  ): HashObject {
    let result = new HashObject();
    parent.querySelectorAll('[data-group-name]').forEach((element: Element) => {
      let isDone = false;
      elements.forEach((done) => {
        if (isDone == false && done == element) {
          isDone = true;
        }
      });
      if (isDone) {
        return;
      }
      elements.push(element);
      let name = element.getAttribute('data-group-name');
      let child = HaoriForm.getChildValues(element, elements);
      if (child != null) {
        result[name] = child;
      }
    });
    parent.querySelectorAll('[name]').forEach((element: Element) => {
      let isDone = false;
      elements.forEach((done) => {
        if (isDone == false && done == element) {
          isDone = true;
        }
      });
      if (isDone) {
        return;
      }
      let name = element.getAttribute('name');
      let subname = null;
      if (name.match(/\[\]$/)) {
        subname = name.substring(0, name.length - 2);
        if (Array.isArray(result[subname]) == false) {
          result[subname] = [];
        }
      }
      switch (element.tagName.toLowerCase()) {
        case 'input': {
          let input = <HTMLInputElement>element;
          let type = element.getAttribute('type');
          let value = input.value;
          if (type != 'checkbox' && value == '') {
            return;
          }
          switch (type) {
            case 'checkbox':
              if (input.getAttribute('value') == 'true') {
                if (input.checked) {
                  result[name] = true;
                }
              } else {
                if (input.checked) {
                  if (result[name] == undefined) {
                    result[name] = [];
                  }
                  (<any[]>result[name]).push(value);
                }
              }
              break;
            case 'radio':
              if (input.checked) {
                result[name] = value;
              }
              break;
            case 'number':
              result[name] = parseFloat(value);
              break;
            case 'file':
              if (subname) {
                (<any[]>result[subname]).push(input.getAttribute('data-data'));
              } else {
                result[name] = input.getAttribute('data-data');
              }
              break;
            default:
              result[name] = value;
          }
          break;
        }
        case 'textarea': {
          let value = (<HTMLTextAreaElement>element).value;
          if (value == '') {
            return;
          }
          result[name] = value;
          break;
        }
        case 'select': {
          let value = (<HTMLSelectElement>element).value;
          if (value == '') {
            return;
          }
          result[name] = value;
          break;
        }
      }
      elements.push(element);
    });
    return result;
  }

  /**
   * 設定値をクエリパラメータとして取得します。
   */
  static getQueryParameter(form: HTMLFormElement): string {
    return HaoriForm.getQueryParameterFromObject(HaoriForm.getValues(form));
  }

  /**
   * オブジェクトをクエリパラメータとして取得します。
   *
   * @param object オブジェクト
   * @param prefix パラメータ名のプレフィックス
   */
  static getQueryParameterFromObject(
    object: HashObject,
    prefix: string = null
  ): string {
    let params: string[] = [];
    for (let key in object) {
      let value = object[key];
      if (Array.isArray(value)) {
        value.forEach((child) => {
          if (typeof child == 'object') {
            params.push(
              HaoriForm.getQueryParameterFromObject(
                child,
                prefix == null ? key : prefix + '.' + key
              )
            );
          } else {
            params.push(
              encodeURIComponent(key) + '=' + encodeURIComponent(value)
            );
          }
        });
      } else if (typeof value == 'object') {
        params.push(
          HaoriForm.getQueryParameterFromObject(
            value,
            prefix == null ? key : prefix + '.' + key
          )
        );
      } else {
        params.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
      }
    }
    return params.join('&');
  }

  /**
   * 親エレメント内の入力エレメントに値を設定します。
   *
   * @param parent 親エレメント
   * @param values 設定する値
   */
  static setValues(parent: Element, values: HashObject) {
    for (let key in values) {
      let value = values[key];
      if (Array.isArray(value)) {
        let sample = value[0];
        if (typeof sample == 'object') {
          let groups = parent.querySelectorAll(`[data-group-name='${key}[]']`);
          groups.forEach((group, index) => {
            HaoriForm.setValues(group, value[index]);
          });
        } else {
          let elements = this.getDirectChild(parent, key);
          elements.forEach((element, index) => {
            HaoriForm.setValue(element, value[index]);
          });
        }
      } else if (typeof value == 'object') {
        let group = parent.querySelector(`[data-group-name='${key}']`);
        if (group != null) {
          HaoriForm.setValues(group, value);
        }
      } else {
        let elements = this.getDirectChild(parent, key);
        elements.forEach((element, index) => {
          HaoriForm.setValue(element, value);
        });
      }
    }
  }

  /**
   * 親エレメントのグループ（data-group-name属性を持っているもの）を含まない直接のエレメントのうちname属性が一致するもののリストを取得します。
   * 
   * @param parent 親エレメント
   * @param name name属性の値
   */
  private static getDirectChild(parent: Element, name: string): Element[] {
    let results: Element[] = [];
    let groups = parent.querySelectorAll('[data-group-name]');
    let elements = parent.querySelectorAll(`[name='${name}']`);
    elements.forEach((element) => {
      let upGroup = element.closest('[data-group-name]');
      let ignore = false;
      groups.forEach((group) => {
        if (group == upGroup) {
          ignore = true;
        }
      });
      if (!ignore) {
        results.push(element);
      }
    });
    return results;
  }

  /**
   * エレメントに値を設定します。
   *
   * @param element エレメント
   * @param value 値
   */
  static setValue(element: Element, value: any) {
    switch (element.tagName.toLowerCase()) {
      case 'input':
        switch (element.getAttribute('type')) {
          case 'checkbox':
          case 'radio':
            if (element.getAttribute('value') == value.toString()) {
              (<HTMLInputElement>element).checked = true;
            }
            break;
          case 'hidden':
            if (element.getAttribute('data-default-value') == null) {
              // hiddenの場合はvalue属性に値が設定されるためリセット用にもとの値を保持しておく
              element.setAttribute(
                'data-default-value',
                element.getAttribute('value')
              );
            }
            (<HTMLInputElement>element).value = value.toString();
            break;
          default:
            (<HTMLInputElement>element).value = value.toString();
        }
        break;
      case 'select':
      case 'textarea':
        (<HTMLTextAreaElement>element).value = value.toString();
        break;
      default:
        element.textContent = value.toString();
    }
  }

  /**
   * フォームの決定処理を行います。同時にフォーム内のメッセージを削除します。
   *
   * @param form フォームエレメント
   */
  static submit(form: HTMLFormElement) {
    HaoriForm.clearAllMessages(form);
    form.submit();
  }

  /**
   * フォームのresetメソッドを呼び出します。同時にフォーム内のメッセージを削除します。
   *
   * @param form フォームエレメント
   */
  static reset(form: HTMLFormElement) {
    HaoriForm.clearAllMessages(form);
    form.querySelectorAll('input[type="file"]').forEach((element) => {
      element.removeAttribute('data-data');
    });
    form.querySelectorAll('input[type="hidden"]').forEach((element) => {
      let value = element.getAttribute('data-default-value');
      if (value == null) {
        value = element.getAttribute('value');
      }
      (<HTMLInputElement>element).value = value;
    });
    form.reset();
  }

  /**
   * 親エレメントに成功メッセージを追加します。既に完了メッセージが存在する場合は先に削除します。
   *
   * @param parent 親エレメント
   * @param message メッセージ
   */
  static addSuccessMessage(parent: Element, message: string) {
    HaoriForm.addMessage(parent, message);
  }

  /**
   * 親エレメント内の入力エレメントにエラーメッセージを追加します。名称が一致しないメッセージは親エレメントに追加します。
   * data-group-name属性のエレメントがある場合は入れ子として扱います。
   *
   * @param parent 親エレメント
   * @param name 名称
   * @param message メッセージ
   */
  static addErrorMessage(parent: Element, name: string, message: string) {
    if (name == null) {
      name = '';
    }
    let elements = parent.querySelectorAll(`[name='${name}']`);
    if (elements.length == 0) {
      let dotPoint = name.indexOf('.');
      if (dotPoint > 0) {
        let prefix = name.substring(0, dotPoint);
        let groups = parent.querySelectorAll(`[data-group-name='${prefix}']`);
        if (groups.length > 0) {
          groups.forEach((group) => {
            HaoriForm.addErrorMessage(
              group,
              name.substring(dotPoint + 1),
              message
            );
          });
          return;
        }
      }
      parent.classList.add(HaoriForm.options.invalidClassName);
      HaoriForm.addMessage(parent, message);
      return;
    }
    elements.forEach((element: Element) => {
      if (parent.getAttribute('data-group-name') != null) {
        let group = element.closest('[data-group-name]');
        if (parent != group) {
          // グループが一致しない（複数の入れ子になっている）ためスキップ
          return;
        }
      }
      element.classList.add(HaoriForm.options.invalidClassName);
      element.parentElement.classList.add(HaoriForm.options.invalidClassName);
      HaoriForm.addMessage(element.parentElement, message);
      let id = element.getAttribute('id');
      if (id != null) {
        let label = parent.querySelector(`[for='${id}']`);
        if (label != null) {
          label.classList.add(HaoriForm.options.invalidClassName);
        }
      }
    });
  }

  /**
   * 親エレメント内のすべてのメッセージを削除します。
   *
   * @param parent 親エレメント
   */
  static clearAllMessages(parent: Element) {
    parent.removeAttribute('data-message');
    parent.classList.remove(HaoriForm.options.invalidClassName);
    parent.querySelectorAll('[data-message]').forEach((element: Element) => {
      element.removeAttribute('data-message');
    });
    parent
      .querySelectorAll(`.${HaoriForm.options.invalidClassName}`)
      .forEach((element: Element) => {
        element.classList.remove(HaoriForm.options.invalidClassName);
      });
    parent.dispatchEvent(
      new CustomEvent('clear-messages', {
        bubbles: true,
        cancelable: true,
        composed: true,
      })
    );
  }

  /**
   * エレメントにメッセージ属性を追加します。
   *
   * @param element 対象エレメント
   * @param message メッセージ
   */
  private static addMessage(element: Element, message: string) {
    let current = element.getAttribute('data-message');
    if (current == null || current == '') {
      current = message;
    } else {
      current += '\n' + message;
    }
    element.setAttribute('data-message', current);
    element.dispatchEvent(
      new CustomEvent('add-message', {
        bubbles: true,
        cancelable: true,
        composed: true,
        detail: message,
      })
    );
  }
}

/**
 * メッセージを格納するクラスです。
 */
class HaoriFormMessage {
  /** 名称 */
  name: string;

  /** メッセージ */
  message: string;
}

/**
 * オプション設定を行うクラスです。
 */
class HaoriFormOptions {
  /** エラーメッセージ追加時に付与されるクラス名 */
  invalidClassName: string;

  constructor() {
    this.invalidClassName = 'invalid';
  }
}
HaoriForm.options = new HaoriFormOptions();

document.addEventListener('DOMContentLoaded', (event) => {
  HaoriForm.bind(document.body);
});

document.addEventListener('load', (event) => {
  HaoriForm.bind(<HTMLElement>event.target);
});

// ファイル選択処理
document.addEventListener('change', (event) => {
  let element = <HTMLElement>event.target;
  if (element.tagName != 'INPUT' || element.getAttribute('type') != 'file') {
    return;
  }
  Array.prototype.forEach.call(
    (<HTMLInputElement>element).files,
    (file: File) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        let data = reader.result as string;
        data = data.substring(data.indexOf(',') + 1);
        element.setAttribute('data-data', data);
      };
      reader.readAsDataURL(file);
    }
  );
});
