/**
 * iframeに関するクラスです。
 *
 * 属性
 * data-replace: この属性が付いていないiframeはイベントの対象外とします
 *
 * イベント
 * load: iframeの中身を入れ替えた際に発生します
 */
class HaoriIframe {
  /**
   * iframeの中にロードされたコンテンツのbodyタグの中身をiframeタグと置き換えます。
   * iframeの中はロード済みである必要があります。
   *
   * @param iframe iframeエレメント
   */
  static replace(iframe: HTMLIFrameElement) {
    let html = iframe.contentDocument.body.innerHTML;
    let div = document.createElement('div');
    div.style.visibility = 'hidden';
    div.setAttribute('data-replace', '');
    div.innerHTML = html;
    iframe.after(div);
    iframe.remove();
    div.dispatchEvent(
      new Event('load', {
        bubbles: true,
        cancelable: true,
        composed: true,
      })
    );
    div.style.visibility = null;
  }

  /**
   * 対象iframeにイベントを関連付けます。
   *
   * @param element
   */
  static bind(iframe: HTMLIFrameElement) {
    if (iframe.contentDocument && iframe.getAttribute('data-replace') != null) {
      HaoriIframe.replace(iframe);
    } else {
      iframe.addEventListener('load', () => {
        if (iframe.getAttribute('data-replace') == null) {
          return;
        }
        HaoriIframe.replace(iframe);
      });
    }
  }
}

window.addEventListener('load', () => {
  document.body
    .querySelectorAll('iframe')
    .forEach((iframe: HTMLIFrameElement) => {
      iframe.style.display = 'none';
      HaoriIframe.bind(iframe);
    });
});

document.addEventListener('load', (event) => {
  let target = <HTMLElement>event.target;
  if (target.tagName == 'IFRAME') {
    return;
  }
  target.querySelectorAll('iframe').forEach((iframe: HTMLIFrameElement) => {
    iframe.style.display = 'none';
    HaoriIframe.bind(iframe);
  });
});
